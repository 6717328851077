import React, { ReactNode } from "react";
import styled from "styled-components";
import { StyleObject } from "../../types/StyleObject.type";
import { ThemeObject } from "../../assets/themes/default";

// TYPES
/**
 * Sizes for the button
 * @type {Sizes}
 * @example
 * import Button, { Sizes as ButtonSizes } from '../../components/Button/Button';
 */
export enum Sizes {
  xs = "1rem",
  s = "2rem", 
  m = "3rem",
  l = "4rem",
  xl = "5rem",
}
interface ButtonProps {
  $color?: string;
  $colorhover?: string;
  $coloractive?: string;
  $colorline?: string;
  $colorlineactive?: string;
  $backgroundcolor?: string;
  $channelshift?: string;
  $bgflash?: boolean;
  $size?: Sizes;
  $align?: 'left'|'center'|'right';
}

// STYLES
const Style:StyleObject = {};
Style.Label = styled.span<ButtonProps>`
  display:inline-block;
  ${props => props.$channelshift === "true" ? `
  && {
    color:#0000ff;
    position:relative;
    mix-blend-mode:screen;
    &::before, &::after {
      content:attr(data-text);
      position:absolute;
      width:100%;
      left:0;
      top:0;
      mix-blend-mode:inherit;
      transition: left 200ms ease;
    }
    &::before {
      color: #00ff00;
    }
    &::after {
      color: #ff0000;
    }
  }`: ""},
`;
Style.SVGWrapper = styled.div`
  position:absolute;
  
  width:100%;
  height:100%;
  inset:0;
  
  text-align: center;
  z-index:-1;
  > svg {
    height:inherit;
    scale:2;
    pointer-events:none;
  }
`;
Style.SVGPath = styled.path`
  fill:var(--clr-line);
  transform-origin: 750px 170px;
  scale:0;
  opacity:0;
  transition:scale calc(var(--flash-duration) * 4) cubic-bezier(.2,0,0,1.2);
  &:nth-child(1) {
    translate: -29% 33%;
  }
  &:nth-child(2) {
    translate: -18% 33.8%;
    rotate: 180deg;
  }
`;
Style.Button = styled.a<ButtonProps>`
  --flash-duration: 0.2s;
	--clr-line:${props => props.$colorline ? props.$colorline : ({theme}) => theme.colors.c12};
  --clr-bg: ${props => props.$backgroundcolor ? props.$backgroundcolor : ({theme}) => theme.colors.c02};
	position: relative;
	display: inline-block;
	background-color: ${({theme}) => theme.colors.c00};
	color: ${props => props.$color ? props.$color : ({theme}) => theme.colors.c01};
  -webkit-tap-highlight-color:  ${({theme}) => theme.colors.c00}; 

	padding: ${({theme}) => theme.spacing.xs.em} ${({theme}) => theme.spacing.l.em};
	border: 0;

	font-size: ${props => props.$size ? props.$size : Sizes.m};
	font-style: italic;
	letter-spacing: 0;
	text-decoration:none;

  text-align: ${props => props.$align ? props.$align : 'center'};
	isolation: isolate;
	filter: invert(0);
	cursor: pointer;
    &::before {
		content: "";
		position: absolute;
		background: linear-gradient(to right, transparent, var(--clr-line), transparent);

		width: 100%;
		height: 2px;
		left: 0;
		top: 50%;
		translate: 0 -50%;
		z-index: -2;
	}
  &::after {
		content: "";
		position: absolute;
		background-color: var(--clr-bg);

		width: 1rem;
		height: 2px;
		left: 50%;
		top: 50%;
		translate: -50% -50%;

		opacity: 0;
		z-index: -2;
		transition: opacity calc(var(--flash-duration));
	}
  &:active {
		--clr-line: ${props => props.$colorlineactive ? props.$colorlineactive : ({theme}) => theme.colors.c13};
		color: ${props => props.$coloractive ? props.$coloractive : ({theme}) => theme.colors.c03};
		${Style.Label} {
			color: inherit;
			mix-blend-mode:initial;
			&::before, &::after {
				opacity:0;
			}
		}
	}
    &:hover {
      animation: flash var(--flash-duration) linear;
      ${Style.Label} {
        letter-spacing: 10px;
        margin-right: -10px;
        animation: text-flash calc(var(--flash-duration)/.15) linear;
        user-select: none;
        &::before {
          left:1px;
        }
      }
		}
		&::after {
			opacity: 1;
		}
		${Style.SVGPath} {
			opacity:1;
			scale:.4;
			transition:scale calc(var(--flash-duration) * 4) cubic-bezier(.2,0,0,1);
		}
	}
  &:not(:hover) {
		${Style.Label} {
			transition-property: letter-spacing, margin-right;
			transition-duration:1s;
			transition-timing-function: ease;
		}
		${Style.SVGPath} {
			transition:
				scale calc(var(--flash-duration)/2) calc(var(--flash-duration)*2) linear,
				opacity calc(var(--flash-duration)*2) ease;
		}
	}

  @keyframes flash {
    0%,
    99% {
      ${props => props.$bgflash ?  `background-color: var(--clr-bg);`: ``}
      filter: invert(1);
    }
    100% {
      filter: invert(0);
    }
  }

  @keyframes text-flash {
    0%, 7.5% {
      transform: scale(2) skewY(4deg);
    }
    7.6%, 15% {
      transform: scale(1.5) skewY(5deg);
    }
    15.1% {
      transform: scale(1.5) skewY(0);
      animation-timing-function: cubic-bezier(0,.8,0,1);
    }
    100% {
      transform: scale(1) skewY(0);
    }
  }
`;

/**
 * Button component
 * @param {string} text - The text to display on the button
 * @param {string} href - The URL to link to
 * @param {string} textAlign - The text alignment
 * @param {string} color - The text color
 * @param {string} colorHover - The text color on hover
 * @param {string} colorActive - The text color on active
 * @param {string} colorLine - The line color
 * @param {string} colorLineActive - The line color on active
 * @param {string} backgroundColor - The background color
 * @param {boolean} channelShift - Whether to apply channel shift effect
 * @param {boolean} bgFlash - Whether to apply background flash effect
 * @param {Sizes} size - The button size
 * @param {Function} onClick - The function to call on click
 * @returns {React.JSX.Element} React.JSX.Element
 * @example
 * return (
 *  <Button
 *    text="Click Me"
 *    channelShift
 *    size={ButtonSizes.s}
 *  />
 * );
 */
const Button = ({
  text, 
  href, 
  textAlign="center", 
  color="", 
  colorHover="", 
  colorActive="", 
  colorLine="", 
  colorLineActive="", 
  backgroundColor="", 
  channelShift=false, 
  bgFlash=false, 
  size=Sizes.m, 
  onClick
}:{
  text: string|ReactNode, 
  href?: string, 
  textAlign?:"left"|"center"|"right", 
  color?: string|(({theme}:{theme:ThemeObject}) => string), 
  colorHover?: string|(({theme}:{theme:ThemeObject}) => string), 
  colorActive?: string|(({theme}:{theme:ThemeObject}) => string), 
  colorLine?: string|(({theme}:{theme:ThemeObject}) => string), 
  colorLineActive?: string|(({theme}:{theme:ThemeObject}) => string), 
  backgroundColor?: string|(({theme}:{theme:ThemeObject}) => string), 
  channelShift?: boolean, 
  bgFlash?: boolean, 
  size?: Sizes, 
  onClick?: (event: PointerEvent) => void
}) => {
    
  return (
    <Style.Button 
      href={href} 
      $align={textAlign} 
      $color={color} 
      $colorhover={colorHover} 
      $coloractive={colorActive} 
      $colorline={colorLine} 
      $colorlineactive={colorLineActive} 
      $backgroundcolor={backgroundColor} 
      $bgflash={bgFlash ? 'true' : undefined} 
      $size={size} 
      onClick={(ev: PointerEvent) => {
        if(onClick) onClick(ev);
      }}
    >
      <Style.Label data-text={text} $channelshift={channelShift.toString()}>{text}</Style.Label>
      <Style.SVGWrapper>
        <svg version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 1024 1024">
          <Style.SVGPath d="M752.8,171.9c0,0-317.8,387.3-739.8,839.1c5-5,998-998,998-998S862,171.9,752.8,171.9z"/>
          <Style.SVGPath d="M752.8,171.9c0,0-317.8,387.3-739.8,839.1c5-5,998-998,998-998S862,171.9,752.8,171.9z"/>
        </svg>
      </Style.SVGWrapper>
    </Style.Button>
  );
}

export default Button;