/**
 * Default theme object type definition
 * @typedef {object} ThemeObject
 * @property {object} colors - COLOR SCHEME
 * @property {object} spacing - SPACING SCHEME
 * @property {object} media - MEDIA BREAKPOINTS
 * @example
 * import theme from '../../assets/themes/default';
 * 
 * // ...
 * 
 * return (
 *  <ThemeProvider theme={theme}>
 *    <Main />
 *  </ThemeProvider>
 * );
 */
export type ThemeObject = {
  colors: {
    c00: string;
    c01: string;
    c01_05: string;
    c01_10: string;
    c01_20: string;
    c01_30: string;
    c01_40: string;
    c01_50: string;
    c01_60: string;
    c01_70: string;
    c01_80: string;
    c01_90: string;
    c02: string;
    c02_10: string;
    c02_20: string;
    c02_30: string;
    c02_40: string;
    c02_50: string;
    c02_60: string;
    c02_70: string;
    c02_80: string;
    c02_90: string;
    c02_95: string;
    c03: string;
    c03_10: string;
    c03_20: string;
    c03_30: string;
    c03_40: string;
    c03_50: string;
    c03_60: string;
    c03_70: string;
    c03_80: string;
    c03_90: string;
    c04: string;
    c04_10: string;
    c04_20: string;
    c04_30: string;
    c04_40: string;
    c04_50: string;
    c04_60: string;
    c04_70: string;
    c04_80: string;
    c04_90: string;
    c05: string;
    c05_10: string;
    c05_20: string;
    c05_30: string;
    c05_40: string;
    c05_50: string;
    c05_60: string;
    c05_70: string;
    c05_80: string;
    c05_90: string;
    c06: string;
    c06_10: string;
    c06_20: string;
    c06_30: string;
    c06_40: string;
    c06_50: string;
    c06_60: string;
    c06_70: string;
    c06_80: string;
    c06_90: string;
    c07: string;
    c07_10: string;
    c07_20: string;
    c07_30: string;
    c07_40: string;
    c07_50: string;
    c07_60: string;
    c07_70: string;
    c07_80: string;
    c07_90: string;
    c08: string;
    c08_10: string;
    c08_20: string;
    c08_30: string;
    c08_40: string;
    c08_50: string;
    c08_60: string;
    c08_70: string;
    c08_80: string;
    c08_90: string;
    c10: string;
    c10_10: string;
    c10_20: string;
    c10_30: string;
    c10_40: string;
    c10_50: string;
    c10_60: string;
    c10_70: string;
    c10_80: string;
    c10_90: string;
    c11: string;
    c11_10: string;
    c11_20: string;
    c11_30: string;
    c11_40: string;
    c11_50: string;
    c11_60: string;
    c11_70: string;
    c11_80: string;
    c11_90: string;
    c12: string;
    c12_10: string;
    c12_20: string;
    c12_30: string;
    c12_40: string;
    c12_50: string;
    c12_60: string;
    c12_70: string;
    c12_80: string;
    c12_90: string;
    c13: string;
    c13_10: string;
    c13_20: string;
    c13_30: string;
    c13_40: string;
    c13_50: string;
    c13_60: string;
    c13_70: string;
    c13_80: string;
    c13_90: string;
  },
  spacing: {
    xxxs: {
      val: number;
      em: string;
      rem: string;
    },
    xxs: {
      val: number;
      em: string;
      rem: string;
    },
    xs: {
      val: number;
      em: string;
      rem: string;
    },
    s: {
      val: number;
      em: string;
      rem: string;
    },
    m: {
      val: number;
      em: string;
      rem: string;
    },
    l: {
      val: number;
      em: string;
      rem: string;
    },
    xl: {
      val: number;
      em: string;
      rem: string;
    },
    xxl: {
      val: number;
      em: string;
      rem: string;
    },
    xxxl: {
      val: number;
      em: string;
      rem: string;
    }
  },
  media: {
    xs: {
      val: number;
      px: string;
    };
    s: {
      val: number;
      px: string;
    };
    m: {
      val: number;
      px: string;
    };
    l: {
      val: number;
      px: string;
    };
    xl: {
      val: number;
      px: string;
    };
  }
}

/**
 * Default theme object
 * @param {object} colors - COLOR SCHEME
 * @param {string} colors[].cxx_xx - transparency on specified color
 * @param {string} colors[].c00 - transparent
 * @param {string} colors[].c01 - white
 * @param {string} colors[].c02 - black
 * @param {string} colors[].c03 - primary (red)
 * @param {string} colors[].c04 - secondary (purple)
 * @param {string} colors[].c05 - info
 * @param {string} colors[].c06 - success
 * @param {string} colors[].c07 - warning
 * @param {string} colors[].c08 - danger
 * @param {string} colors[].c10 - gray
 * @param {string} colors[].c11 - lightgray
 * @param {string} colors[].c12 - darkgray
 * @param {string} colors[].c13 - dark primary (red)
 * 
 * @param {object} spacing - SPACING SCHEME
 * @param {number} spacing[].xxxs.val - 0.125
 * @param {string} spacing[].xxxs.rem - 0.125rem
 * @param {string} spacing[].xxxs.em - 0.125em
 * @param {number} spacing[].xxs.val - 0.25
 * @param {string} spacing[].xss.rem - 0.25rem
 * @param {string} spacing[].xss.em - 0.25em
 * @param {number} spacing[].xs.val - 0.5
 * @param {string} spacing[].xs.rem - 0.5rem
 * @param {string} spacing[].xs.em - 0.5em
 * @param {number} spacing[].s.val - 0.75
 * @param {string} spacing[].s.rem - 0.75rem
 * @param {string} spacing[].s.em - 0.75em
 * @param {number} spacing[].m.val - 1
 * @param {string} spacing[].m.rem - 1rem
 * @param {string} spacing[].m.em - 1em
 * @param {number} spacing[].l.val - 1.5
 * @param {string} spacing[].l.rem - 1.5rem
 * @param {string} spacing[].l.em - 1.5em
 * @param {number} spacing[].xl.val - 2
 * @param {string} spacing[].xl.rem - 2rem
 * @param {string} spacing[].xl.em - 2em
 * @param {number} spacing[].xxl.val - 3
 * @param {string} spacing[].xxl.rem - 3rem
 * @param {string} spacing[].xxl.em - 3em
 * @param {number} spacing[].xxxl.val - 4
 * @param {string} spacing[].xxxl.rem - 4rem
 * @param {string} spacing[].xxxl.em - 4em
 * 
 * @param {object} media - MEDIA BREAKPOINTS
 * @param {number} media[].xs.val - 400
 * @param {string} media[].xs.px - 400px
 * @param {number} media[].s.val - 500
 * @param {string} media[].s.px - 500px
 * @param {number} media[].m.val - 600
 * @param {string} media[].m.px - 600px
 * @param {number} media[].l.val - 900
 * @param {string} media[].l.px - 900px
 * @param {number} media[].xl.val - 1200
 * @param {string} media[].xl.px - 1200px
 * @example
 * import theme from '../../assets/themes/default';
 * 
 * // ...
 * 
 * return (
 * <ThemeProvider theme={theme}>
 *  <Main />
 * </ThemeProvider>
 * );
 */
const theme: ThemeObject = {
  colors: {
    c00: '#00000000',
    c01: '#ffffff',
    c01_05: '#ffffff0d',
    c01_10: '#ffffff1a',
    c01_20: '#ffffff33',
    c01_30: '#ffffff4d',
    c01_40: '#ffffff66',
    c01_50: '#ffffff80',
    c01_60: '#ffffff99',
    c01_70: '#ffffffb3',
    c01_80: '#ffffffcc',
    c01_90: '#ffffffe6',
    c02: '#000000',
    c02_10: '#0000001a',
    c02_20: '#00000033',
    c02_30: '#0000004d',
    c02_40: '#00000066',
    c02_50: '#00000080',
    c02_60: '#00000099',
    c02_70: '#000000b3',
    c02_80: '#000000cc',
    c02_90: '#000000e6',
    c02_95: '#000000f2',
    c03: "#a30f0f",
    c03_10: "#a30f0f1a",
    c03_20: "#a30f0f33",
    c03_30: "#a30f0f4d",
    c03_40: "#a30f0f66",
    c03_50: "#a30f0f80",
    c03_60: "#a30f0f99",
    c03_70: "#a30f0fb3",
    c03_80: "#a30f0fcc",
    c03_90: "#a30f0fe6",
    c04: "#821de0",
    c04_10: "#821de01a",
    c04_20: "#821de033",
    c04_30: "#821de04d",
    c04_40: "#821de066",
    c04_50: "#821de080",
    c04_60: "#821de099",
    c04_70: "#821de0b3",
    c04_80: "#821de0cc",
    c04_90: "#821de0e6",
    c05: "#1d82e0",
    c05_10: "#1d82e01a",
    c05_20: "#1d82e033",
    c05_30: "#1d82e04d",
    c05_40: "#1d82e066",
    c05_50: "#1d82e080",
    c05_60: "#1d82e099",
    c05_70: "#1d82e0b3",
    c05_80: "#1d82e0cc",
    c05_90: "#1d82e0e6",
    c06: "#1de082",
    c06_10: "#1de0821a",
    c06_20: "#1de08233",
    c06_30: "#1de0824d",
    c06_40: "#1de08266",
    c06_50: "#1de08280",
    c06_60: "#1de08299",
    c06_70: "#1de082b3",
    c06_80: "#1de082cc",
    c06_90: "#1de082e6",
    c07: "#e0c71d",
    c07_10: "#e0c71d1a",
    c07_20: "#e0c71d33",
    c07_30: "#e0c71d4d",
    c07_40: "#e0c71d66",
    c07_50: "#e0c71d80",
    c07_60: "#e0c71d99",
    c07_70: "#e0c71db3",
    c07_80: "#e0c71dcc",
    c07_90: "#e0c71de6",
    c08: "#e01d1d",
    c08_10: "#e01d1d1a",
    c08_20: "#e01d1d33",
    c08_30: "#e01d1d4d",
    c08_40: "#e01d1d66",
    c08_50: "#e01d1d80",
    c08_60: "#e01d1d99",
    c08_70: "#e01d1db3",
    c08_80: "#e01d1dcc",
    c08_90: "#e01d1de6",
    c10: "#666c75",
    c10_10: "#666c751a",
    c10_20: "#666c7533",
    c10_30: "#666c754d",
    c10_40: "#666c7566",
    c10_50: "#666c7580",
    c10_60: "#666c7599",
    c10_70: "#666c75b3",
    c10_80: "#666c75cc",
    c10_90: "#666c75e6",
    c11: "#abb7c7",
    c11_10: "#abb7c71a",
    c11_20: "#abb7c733",
    c11_30: "#abb7c74d",
    c11_40: "#abb7c766",
    c11_50: "#abb7c780",
    c11_60: "#abb7c799",
    c11_70: "#abb7c7b3",
    c11_80: "#abb7c7cc",
    c11_90: "#abb7c7e6",
    c12: "#252c38",
    c12_10: "#252c381a",
    c12_20: "#252c3833",
    c12_30: "#252c384d",
    c12_40: "#252c3866",
    c12_50: "#252c3880",
    c12_60: "#252c3899",
    c12_70: "#252c38b3",
    c12_80: "#252c38cc",
    c12_90: "#252c38e6",
    c13: "#390f0f",
    c13_10: "#390f0f1a",
    c13_20: "#390f0f33",
    c13_30: "#390f0f4d",
    c13_40: "#390f0f66",
    c13_50: "#390f0f80",
    c13_60: "#390f0f99",
    c13_70: "#390f0fb3",
    c13_80: "#390f0fcc",
    c13_90: "#390f0fe6"
  },
  spacing: {
    xxxs: {
      val: 0.125,
      em: '0.125em',
      rem: '0.125rem'
    },
    xxs: {
      val: 0.25,
      em: '0.25em',
      rem: '0.25rem'
    },
    xs: {
      val: 0.5,
      em: '0.5em',
      rem: '0.5rem'
    },
    s: {
      val: 0.75,
      em: '0.75em',
      rem: '0.75rem'
    },
    m: {
      val: 1,
      em: '1em',
      rem: '1rem'
    },
    l: {
      val: 1.5,
      em: '1.5em',
      rem: '1.5rem'
    },
    xl: {
      val: 2,
      em: '2em',
      rem: '2rem'
    },
    xxl: {
      val: 3,
      em: '3em',
      rem: '3rem'
    },
    xxxl: {
      val: 4,
      em: '4em',
      rem: '4rem'
    }
  },
  media: {
    xs: {
      val: 400,
      px: '400px'
    },
    s: {
      val: 500,
      px: '500px'
    },
    m: {
      val: 600,
      px: '600px'
    },
    l: {
      val: 900,
      px: '900px'
    },
    xl: {
      val: 1200,
      px: '1200px'
    }
  }
}

export default theme;