import React, { Dispatch, SetStateAction, useState } from 'react'
import { StyleObject } from '../../types/StyleObject.type';
import styled from 'styled-components';
import { ThemeObject } from '../../assets/themes/default';

/**
 * Data for the Timeline
 * @type {TimelineData}
 * @property {string} chapter Chapter "number" / abbreviation
 * @property {string} title Chapter title
 * @property {string} description Chapter description
 * @property {string} href Chapter href
 * @example
 * import Timeline, { TimelineData } from '../../components/Timeline/Timeline';
 */
export type TimelineData = {
  chapter: string;
  title?: string;
  description?: string;
  href?: string;
}

/**
 * Sizes for the Timeline
 * @type {Sizes}
 * @example
 * import Timeline, { Sizes as TimelineSizes } from '../../components/Timeline/Timeline';
 */
export enum Sizes {
  xs = "1rem",
  s = "1.5rem", 
  m = "2rem",
  l = "3.5rem",
  xl = "4rem",
}
interface TimelineProps {
  $color?: string;
  $coloractive?: string;
  $size?: Sizes;
  $currentchapter?: string;
  $blinkchapter?: boolean;
  title?: string;
}

// STYLES
const Style:StyleObject = {};
Style.Chapter = styled.a<TimelineProps>`
  position: relative;
  -webkit-tap-highlight-color:  ${({theme}) => theme.colors.c00}; 

  min-width: 5ch;
  padding: ${({theme}) => theme.spacing.m.rem};
  
  text-align: center;
  user-select: none;
  transition: color 1000ms ease;
  cursor: pointer;
  &::before {
    position: absolute;
    content: ${({title}) => title ? `'${title}'` : '""'};
    color: ${({$color: color}) => color ? color : ({theme}) => theme.colors.c01};

    width: max-content;
    top: -${({theme}:{theme:ThemeObject}) => theme.spacing.m.rem};
    left: 50%;
    letter-spacing: ${({theme}:{theme:ThemeObject}) => theme.spacing.s.rem};

    pointer-events: none;
    opacity: 0;
    translate: -50% 50%;
    transition: 
      opacity 400ms ease, 
      letter-spacing 1000ms cubic-bezier(0,.5,.5,1) 400ms, 
      translate 1000ms cubic-bezier(0,.5,.5,1) 400ms;
  }
  &:hover::before {
    letter-spacing: ${({theme}:{theme:ThemeObject}) => theme.spacing.xxxs.rem};

    translate: -50% 0;
    pointer-events: auto;
    opacity: 1;
    transition-delay: 0s;
  }
`;
Style.Timeline = styled.div<TimelineProps>`
  display: flex;
  color: ${({$color: color}) => color ? color : ({theme}) => theme.colors.c10_70};
  position: fixed;
  bottom: 0;

  width:100%;
  max-width: ${({theme}) => theme.media.xl.px};
  padding-inline: ${({theme}) => theme.spacing.l.rem};

  font-family: 'Playfair Display';
  font-size: ${({$size: size}) => size ? size : Sizes.s};

  justify-content: space-evenly;
  align-self: center;
  transition: color 400ms ease;
  ${Style.Chapter}:nth-child(${({$currentchapter: currentchapter}) => currentchapter}) {
    color: ${({$coloractive: coloractive}) => coloractive ? coloractive : ({theme}) => theme.colors.c01};
    ${({$blinkchapter: blinkchapter}) => blinkchapter ? `animation: blink 4s infinite ease-in-out;` : ''}
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .4;
    }
    100% {
      opacity: 1;
    }
  }
`;

/**
 * Timeline component
 * @param color Timeline color
 * @param colorActive Timeline active color
 * @param timelines Timeline data
 * @param size Timeline size
 * @param highlightChapter Highlight chapter
 * @param blinkChapter Blink chapter
 * @param onChapterClick On chapter click
 * @returns {React.JSX.Element} React.JSX.Element
 * @example
 * return (
 *  <Timeline
 *    color="#ff0000"
 *    colorActive="#00ff00"
 *    timelines={[
 *      {chapter: "1", title: "Chapter 1", description: "Description 1", href: "#"},
 *      {chapter: "2", title: "Chapter 2", description: "Description 2", href: "#"},
 *      {chapter: "3", title: "Chapter 3", description: "Description 3", href: "#"},
 *    ]}
 *    size={Sizes.m}
 *    highlightChapter={1}
 *    blinkChapter={false}
 *    onChapterClick={(ev, index, setCurrentChapter) => {
 *      setCurrentChapter(index);
 *    }}
 *  />
 * );
 */
const Timeline = ({
  color="",
  colorActive="",
  timelines=[],
  size=Sizes.xs,
  highlightChapter=1,
  blinkChapter=false,
  onChapterClick
}:{
  color?: string|(({theme}:{theme:ThemeObject}) => string),
  colorActive?: string|(({theme}:{theme:ThemeObject}) => string),
  timelines:TimelineData[],
  size?:Sizes
  highlightChapter?:number,
  blinkChapter?:boolean,
  onChapterClick?: (ev:PointerEvent, index:number, setCurrentChapter:Dispatch<SetStateAction<typeof highlightChapter>>) => void
}) => {
  const [currentChapter, setCurrentChapter] = useState(highlightChapter);
  
  return (
    <Style.Timeline 
      $color={color} 
      $coloractive={colorActive} 
      $size={size} 
      $currentchapter={currentChapter.toString()}
      $blinkchapter={blinkChapter}
    >
      {timelines.map((timeline, index) => (
        <Style.Chapter 
          href={timeline.href} 
          key={index} 
          title={timeline.title} 
          onClick={(ev) => {
            if(onChapterClick) onChapterClick(ev, index+1, setCurrentChapter);
          }}
        >
          {timeline.chapter}
        </Style.Chapter>
      ))}
    </Style.Timeline>
  )
}

export default Timeline