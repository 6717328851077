import { ISourceOptions, MoveDirection, OutMode } from "@tsparticles/engine";

/**
 * Settings for the particles
 * @type {ISourceOptions}
 * @example
 * import options from './tsparticles';
 * 
 * // ... 
 * 
 * return (
 * <Particles
 *  options={options}
 * />
 * );
 */
const options: ISourceOptions = ({
  particles: {
    color: {
      value: "#ffffff",
    },
    move: {
      direction: MoveDirection.topLeft,
      enable: true,
      outModes: {
        default: OutMode.out,
      },
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        width:1920
      },
      value: 1000,
    },
    opacity: {
      value: { min: 0, max: .8 },
      
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: .1, max: 1 },
    },
  },
  detectRetina: true,
});

export default options;