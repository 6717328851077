import React, { useEffect, useMemo, useState } from 'react';
import Main from '../pages/Main/Main';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { type ISourceOptions } from "@tsparticles/engine";
import { loadSlim } from '@tsparticles/slim';
import tsParticlesConfig from '../assets/configs/tsparticles';

import { StyleObject } from '../types/StyleObject.type';
import styled from 'styled-components';
// import { loadAll } from "@tsparticles/all";
// import { loadFull } from "tsparticles";
// import { loadBasic } from "@tsparticles/basic";

const Style:StyleObject = {};
Style.Particles = styled(Particles)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  &::after {
    content: '';
    position: absolute;
    background-image: linear-gradient(0deg, ${({ theme }) => theme.colors.c01_05}, ${({ theme }) => theme.colors.c00} 4%, ${({ theme }) => theme.colors.c02} 80%);
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    z-index: 0;
  }
`;

/**
 * App component
 * @returns {React.JSX.Element} React.JSX.Element
 * @example
 * return (
 *  <App />
 * );
 */
  function App(): React.JSX.Element {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = useMemo(() => tsParticlesConfig, []);

  return (
    <>
      {( init ) ? 
        <Style.Particles
          options={options}
          /> : <></>
      }
      <Main />
    </>
  );
}

export default App;
