import { createGlobalStyle } from 'styled-components';
import theme from './default';

// STYLES
const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
      box-sizing: border-box;
    }

    body, html {
      position: relative;
      color: ${theme.colors.c01};
      background-color: ${theme.colors.c02};
      
      min-height: 100vh;
      margin: 0;
      padding: 0;

      font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif, 'Playfair Display';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      z-index: -9999;
    }
`;

export default GlobalStyle