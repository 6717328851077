import React from 'react'
import { StyleObject } from '../../types/StyleObject.type';
import styled from 'styled-components';
import { ThemeObject } from '../../assets/themes/default';
import Label, {Sizes as LabelSizes} from '../Label/Label';

/**
 * Branding component props
 * @type {BrandingComponentProps}
 * @property {string} color Color of the branding
 * @property {boolean} showLogo Show logo
 * @property {boolean} showText Show text
 * @example
 * import Branding, { BrandingComponentProps } from '../../components/Branding/Branding';
 */
export type BrandingComponentProps = {
  color?: string|(({theme}:{theme:ThemeObject}) => string);
  showLogo?: boolean;
  showText?: boolean;
}

interface BrandingProps {
  $color: string;
}

// STYLES
const Style:StyleObject = {};
Style.Logo = styled.svg<BrandingProps>`
  fill: ${({$color}) => $color ? $color : ({theme}) => theme.colors.c01};
  width: ${({theme}) => theme.spacing.xxxl.rem};
`;
Style.Branding = styled.div<BrandingProps>`
  display:flex;
  padding: ${({theme}) => theme.spacing.xl.rem};
  gap: ${({theme}) => theme.spacing.l.rem};
  align-items:center;
`;

/**
 * Branding component
 * @param {string} color - Color of the branding
 * @param {boolean} showLogo - Show logo
 * @param {boolean} showText - Show text
 * @returns {React.JSX.Element} React.JSX.Element
 * @example
 * <Branding color="#ff0000" showLogo={true} showText={true} />
 */
const Branding = ({color="", showLogo=true, showText=true}:BrandingComponentProps) => {
  return (
    <Style.Branding>
      {showLogo ? 
      <Style.Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 806.45 967.5" $color={color}>
        <title>Kurushimi</title>
        <path
          d="M362.5,593.43C316,550.16,240.82,505.69,171.59,486.74c20.94-7.72,43.27-18.92,66.43-33.42,45.22-28.31,91.45-67.61,133.71-113.65,69.84-76.09,118.86-159.44,139.64-234.75,9.24,36.73,22.45,71.1,31.88,93.38,21.19,50.11,47.56,97.47,62.69,112.6l4.75,4.75,6.4-2c2.59-.84,3.46-1.11,109.67-84.43Z"
          transform="translate(-108.28 -28.5)" />
        <path
          d="M510.63,142.48c7.08,21.94,15,42.52,22.49,60.1,22,52.09,48.77,99.83,65,116.1l9.51,9.5,6.67-2.14L362.08,578.3C323.9,545,265,507.11,203.6,485a421.1,421.1,0,0,0,40.26-22.37c46-28.82,93.06-68.77,136-115.53,60.45-65.86,105.59-137.26,130.8-204.63m.87-114v0Zm0,.52C511.55,225.67,251,487.87,110,488.26c85.53.84,198.31,66.19,252.7,120.58L864.79,106.7S616.44,302.25,613.72,303.13C588.84,278.25,511.76,128.41,511.5,29ZM108.28,488.25h0Z"
          transform="translate(-108.28 -28.5)" />
        <path
          d="M660.88,398.25c34,29.85,78.08,57.19,126.21,78.18,10.81,4.71,21.57,9,32.12,12.82-50.88,22.83-104.6,62-156,114.77-68.61,70.37-121.74,153-150.92,233.22-21.1-70.24-53.16-137.48-85.9-177.74l-12-14.74L660.88,398.25m-.53-30.59L158.21,869.8S409,674,409.28,673.37C448,721,487,812,504.74,896.6a38.24,38.24,0,0,1-.11,10.27C499.36,935.73,493,960.06,493,971.5c0,15.5,8.28,24.5,18.5,24.5s18.5-8,18.5-24.5c0-12.39-6.76-35.18-12.1-64.53a47.13,47.13,0,0,1-.22-10.93C562,702,771,488.25,914.72,488.25c-53.23,0-178.87-45.1-254.37-120.59Z"
          transform="translate(-108.28 -28.5)" />
      </Style.Logo>
      : <></>}
      {showText ? 
      <Label 
        text="Kurushimi"
        fontFamily='Starstruck'
        color={color}
        size={LabelSizes.s}
       /> 
      : <></>}
    </Style.Branding>
  )
}

export default Branding