import React, { ReactNode } from "react";
import styled from "styled-components";
import { StyleObject } from "../../types/StyleObject.type";
import { ThemeObject } from "../../assets/themes/default";

// TYPES
/**
 * Sizes for the Label
 * @type {Sizes}
 * @example
 * import Label, { Sizes as LabelSizes } from '../../components/Label/Label';
 */
export enum Sizes {
  xxs = "0.5rem",
  xs = "1rem",
  s = "2rem", 
  m = "3rem",
  l = "4rem",
  xl = "5rem",
  xxl = "6rem",
  xxxl = "7rem",
}
interface LabelProps {
  $color?: string;
  $colorhover?: string;
  $coloractive?: string;
  $fontFamily?: 'Nunito Sans'|'Playfair Display'|'Starstruck';
  $fontWeight?: string;
  $fontStyle?: string;
  $letterSpacing?: string;
  $size?: Sizes;
  $padding?: string;
  $margin?: string;
  $align?: 'left'|'center'|'right';
}

// STYLES
const Style:StyleObject = {};
Style.Span = styled.span<LabelProps>`
  color: ${({$color: color}) => color ? color : ({theme}) => theme.colors.c01};
  
  padding: ${({$padding: padding}) => padding ? padding : '0'};
  margin: ${({$margin: margin}) => margin ? margin : '0'};
  
  font-family: ${({$fontFamily: fontFamily}) => fontFamily ? fontFamily : 'Nunito Sans'};
  font-weight: ${({$fontWeight: fontWeight}) => fontWeight ? fontWeight : '400'};
  font-style: ${({$fontStyle: fontStyle}) => fontStyle ? fontStyle : 'normal'};
  font-size: ${({$size: size}) => size ? size : Sizes.xs};
  letter-spacing: ${({$letterSpacing: letterSpacing}) => letterSpacing ? letterSpacing : '0px'};

  text-align: ${({$align: align}) => align ? align : 'center'};
  transition: color 400ms ease;
  isolation: isolate;
  ${({$colorhover: colorhover}) => colorhover ? `
    &:hover { 
      color: ${colorhover}; 
    }` : ''
  }
  ${({$coloractive: coloractive}) => coloractive ? `
    &:active { 
      color: ${coloractive}; 
      transition: color 0s ease;
    }` : ''
  }
`;

/**
 * Label component, standardized text display
 * @param {string|ReactNode} text - Display text
 * @param {'left'|'center'|'right'} textAlign - text align
 * @param {'Nunito Sans'|'Playfair Display'|'Starstruck'} fontFamily - font family
 * @param {Sizes} size - Label size
 * @param {string} fontWeight - font weight
 * @param {string} fontStyle - font style
 * @param {string} letterSpacing - letter spacing
 * @param {string} color - color
 * @param {string} colorHover - color on hover
 * @param {string} colorActive - color on active
 * @param {string} padding - padding
 * @param {string} margin - margin
 * @param {string} href - href link
 * @param {(ev: PointerEvent) => void} onClick - on click event
 * @param {ReactNode} children - children
 * @param {string} iconCode - icon code (font awesome)
 * @param {'fa-brands'|'fa-regular'|'fa-solid'} iconType - icon type (font awesome)
 * @returns {React.JSX.Element} React.JSX.Element
 * @example
 * return (
 *  <Label
 *    text="Hello World"
 *    size={Sizes.m}
 *  />
 * );
 */
const Label = ({
  text,
  textAlign='center',
  fontFamily='Nunito Sans',
  size=Sizes.xs,
  fontWeight='400',
  fontStyle='normal',
  letterSpacing='',
  color="",
  colorHover="",
  colorActive="",
  padding="",
  margin=""
}:{
  text: string|ReactNode;
  textAlign?: 'left'|'center'|'right';
  fontFamily?: 'Nunito Sans'|'Playfair Display'|'Starstruck';
  size?: Sizes;
  fontWeight?: string;
  fontStyle?: string;
  letterSpacing?: string;
  color?: string|(({theme}:{theme:ThemeObject}) => string);
  colorHover?: string|(({theme}:{theme:ThemeObject}) => string);
  colorActive?: string|(({theme}:{theme:ThemeObject}) => string);
  padding?: string;
  margin?: string;
}) => {
  return (
    <Style.Span 
      $color={color}
      $colorhover={colorHover}
      $coloractive={colorActive}
      $fontFamily={fontFamily} 
      $fontWeight={fontWeight} 
      $fontStyle={fontStyle} 
      $letterSpacing={letterSpacing}
      $size={size} 
      $padding={padding}
      $margin={margin}
      $align={textAlign}
    >
      {text}
    </Style.Span>
  );
};

export default Label;