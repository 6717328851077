import React from 'react'
import Button, { Sizes as ButtonSizes } from '../../components/Button/Button'
import styled from 'styled-components';
import { StyleObject } from '../../types/StyleObject.type';
import IconButton, { Sizes as IconButtonSizes } from '../../components/IconButton/IconButton';
import Timeline, { Sizes as TimelineSizes } from '../../components/Timeline/Timeline';
import Branding from '../../components/Branding/Branding';

// STYLES
const Style:StyleObject = {};
Style.Main = styled.div`
  display:flex;
  flex-direction:column;
`;

/**
 * Main page component
 * @returns {React.JSX.Element} React.JSX.Element
 * @example
 * return (
 *  <Main />
 * );
 */
const Main = () => {
  return (
    <Style.Main>
      <Branding 
        
      />
      <Button 
        text="Click Me"
        channelShift
        size={ButtonSizes.s}
      />
      <IconButton 
        iconCode='fa-discord'
        iconType='fa-brands'
        size={IconButtonSizes.s}
      />
      <Timeline 
        timelines={[
          {
            chapter:'P',
            title:'Prologue',
          },
          {
            chapter:'I',
            title:"Gaze Upon Power"
          },
          {
            chapter:'II',
            title:"???"
          },
          {
            chapter:'III',
            title:"???"
          },
          {
            chapter:'E',
            title:"Epilogue"
          },
        ]}
        size={TimelineSizes.xs}
        highlightChapter={1}
        onChapterClick={(ev, index, setCurrentChapter) => setCurrentChapter(index)}
      />
    </Style.Main>
  )
}

export default Main